import { Container, Paper, Typography, ToggleButtonGroup, ToggleButton, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { PeopleSelector } from "../components/PeopleSelector.comp";
import EuroIcon from "@mui/icons-material/Euro";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import { motion } from "framer-motion";
import axios from "axios";
import Papa from "papaparse";

export function SettingsPage() {
	const storageUser = localStorage.getItem("defaultUser");
	const [defaultUser, setDefaultUser] = useState(storageUser || "");
	const storageDisplay = localStorage.getItem("currencyDisplay");
	const [currencyDisplay, setCurrencyDisplay] = useState(storageDisplay || "euro");
	const storageInput = localStorage.getItem("defaultInput");
	const [defaultInput, setDefaultInput] = useState(storageInput || "euro");
	const [priceList, setPriceList] = useState([]);

	useEffect(() => {
		axios
			.get("/api/payment/get/all")
			.then((response) => {
				setPriceList(response.data);
			})
			.catch((error) => {
				console.log(error);
			});
	}, []);

	function clickDownload() {
		const csv = Papa.unparse(priceList, { delimiter: ";" });
		const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
		const link = document.createElement("a");
		link.href = URL.createObjectURL(blob);
		let currentDate = new Date().toJSON().slice(0, 19);

		link.download = `TravelTabData_${currentDate}.csv`;
		link.click();
	}

	function handleUser(e) {
		if (e.target.value) {
			if (e.target.value === defaultUser) {
				setDefaultUser("all");
			} else {
				setDefaultUser(e.target.value);
			}
		}
	}

	function handleCurrencyDisplay(e) {
		if (e.target.value && e.target.value !== currencyDisplay) {
			setCurrencyDisplay(e.target.value);
		}
	}
	function handleDefaultInput(e) {
		if (e.target.value && e.target.value !== defaultInput) {
			setDefaultInput(e.target.value);
		}
	}

	useEffect(() => {
		if (defaultUser && defaultUser !== storageUser) {
			localStorage.setItem("defaultUser", defaultUser);
		}
	}, [defaultUser, storageUser]);

	useEffect(() => {
		if (currencyDisplay && currencyDisplay !== storageDisplay) {
			localStorage.setItem("currencyDisplay", currencyDisplay);
		}
	}, [currencyDisplay, storageDisplay]);

	useEffect(() => {
		if (defaultInput && defaultInput !== storageInput) {
			localStorage.setItem("defaultInput", defaultInput);
		}
	}, [defaultInput, storageInput]);

	return (
		<Container
			key="SettingsPage"
			initial={{ y: 50, zIndex: 0, opacity: 0 }}
			animate={{ y: 0, zIndex: 1, opacity: 1 }}
			exit={{ y: 50, zIndex: 0, opacity: 0 }}
			component={motion.div}>
			<Paper sx={{ mt: 2, p: 2 }}>
				<Typography variant="h3">Settings</Typography>
				<Typography
					variant="h4"
					sx={{ mt: 3 }}>
					Currency display
				</Typography>
				<Typography color="gray">Should prices show in dollars or euros</Typography>
				<ToggleButtonGroup
					color="PeopleSelector"
					exclusive
					onChange={handleCurrencyDisplay}
					value={currencyDisplay}
					fullWidth>
					<ToggleButton value="dollar">
						<AttachMoneyIcon />
						Dollar
					</ToggleButton>
					<ToggleButton value="euro">
						<EuroIcon />
						Euro
					</ToggleButton>
				</ToggleButtonGroup>
				<Typography
					variant="h4"
					sx={{ mt: 3 }}>
					Default input
				</Typography>
				<Typography color="gray">Will you pay with dollars or euros</Typography>

				<ToggleButtonGroup
					color="PeopleSelector"
					exclusive
					onChange={handleDefaultInput}
					value={defaultInput}
					fullWidth>
					<ToggleButton value="dollar">
						<AttachMoneyIcon />
						Dollar
					</ToggleButton>
					<ToggleButton value="euro">
						<EuroIcon />
						Euro
					</ToggleButton>
				</ToggleButtonGroup>
				<Typography
					variant="h4"
					sx={{ mt: 3 }}>
					Default user
				</Typography>
				<Typography color="gray">Who is the main user of this device</Typography>

				<PeopleSelector
					selector={defaultUser}
					handleSelect={handleUser}
				/>
				<Typography
					variant="h4"
					sx={{ mt: 3 }}>
					Download all data
				</Typography>
				<Typography color="gray">As a comma separated value database file</Typography>

				<Button
					onClick={clickDownload}
					sx={{ mt: 1 }}
					fullWidth
					variant="contained"
					color="secondary">
					Download
				</Button>
			</Paper>
		</Container>
	);
}
