import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import React from "react";

export function PeopleSelector({ selector, handleSelect, unique }) {
	if (unique === undefined) {
		unique = true;
	}
	return (
		<ToggleButtonGroup
			color="PeopleSelector"
			sx={{ width: "100%" }}
			value={selector}
			exclusive={unique}
			onChange={handleSelect}>
			<ToggleButton
				sx={{ width: "33.33%" }}
				value="ac">
				Alwin &
				<br />
				Charla
			</ToggleButton>
			<ToggleButton
				sx={{ width: "33.33%" }}
				value="ke">
				Koen &
				<br />
				Eline
			</ToggleButton>
			<ToggleButton
				sx={{ width: "33.33%" }}
				value="ps">
				Pascal &
				<br />
				Steve
			</ToggleButton>
		</ToggleButtonGroup>
	);
}
