import {
	Container,
	Paper,
	Grid,
	Table,
	TableRow,
	TableCell,
	TableBody,
	TableHead,
	Button,
	Typography,
	Chip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { SummaryInfo } from "../dialogs/SummaryInfo.dialog";
import { BalanceInfo } from "../dialogs/BalanceInfo.dialog";
import { useMediaQuery } from "react-responsive";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import { Loader } from "../dialogs/Loader.dialog";
import { NameBlock } from "../components/NameBlock.comp";
import { PrintMoney } from "../helpers/PrintMoney.helper";
import { motion } from "framer-motion";
import { NameDict } from "../helpers/NameDict.helper";
import { useNavigate } from "react-router-dom";

export function HomePage() {
	const isPortrait = useMediaQuery({ query: "(orientation: portrait)" });
	const [priceList, setPriceList] = useState();
	const [owings, setOwings] = useState();
	const [adorned, setAdorned] = useState({});
	const [summaryHelpOpen, setSummaryHelpOpen] = useState(false);
	const [balanceHelpOpen, setBalanceHelpOpen] = useState(false);
	const [loading, setLoading] = useState(true);
	const navigate = useNavigate();

	useEffect(() => {
		axios
			.get("/api/payment/get/all")
			.then((response) => {
				setPriceList(response.data);
			})
			.catch((error) => {
				console.log(error);
			});
	}, []);

	useEffect(() => {
		if (priceList) {
			if (priceList.length === 0) {
				setLoading(false);
				return;
			}
			let owings = { ac: 0, ke: 0, ps: 0 };
			let adorned = { ac: 0, ke: 0, ps: 0 };
			Object.keys(owings).forEach((element) => {
				priceList.forEach((priceElement) => {
					if (priceElement.PaymentSource === element) {
						owings[element] -= priceElement.PaymentAmount;
					}
					const dividedAmount = priceElement.PaymentAmount / priceElement.PaymentGoal.length;
					if (priceElement.PaymentGoal.includes(element)) {
						owings[element] += dividedAmount;
					}
				});
				adorned[element] = PrintMoney(owings[element]);
			});
			setOwings(owings);
			Object.keys(owings).forEach((element) => {
				adorned[element] = PrintMoney(owings[element]);
			});
			setAdorned(adorned);
			setLoading(false);
		}
	}, [priceList]);

	return (
		<>
			<Loader loading={loading} />

			<SummaryInfo
				helpOpen={summaryHelpOpen}
				closeHelp={() => {
					setSummaryHelpOpen(false);
				}}
			/>
			<BalanceInfo
				helpOpen={balanceHelpOpen}
				closeHelp={() => {
					setBalanceHelpOpen(false);
				}}
			/>
			{priceList ? (
				<Container
					key="HomePage"
					initial={{ y: 50, zIndex: 0, opacity: 0 }}
					animate={{ y: 0, zIndex: 1, opacity: 1 }}
					exit={{ y: 50, zIndex: 0, opacity: 0 }}
					component={motion.div}>
					<Paper style={{ marginTop: "1em", marginBottom: "2em", padding: "1em" }}>
						<Grid container>
							<Grid
								item
								xs={12}>
								<Typography
									className="clickableObject"
									variant="h3"
									onClick={() => {
										setSummaryHelpOpen(true);
									}}>
									Summary <InfoOutlinedIcon />
								</Typography>
								<Typography color="gray">Try to not fall behind</Typography>
							</Grid>
							<Grid
								item
								xs={12}
								className="mt-1">
								<Chip
									variant="outlined"
									className="w-100"
									color={owings && (owings["ac"] <= 0 ? "success" : "error")}
									label={owings ? `Alwin & Charla  ${adorned["ac"]}` : ""}
								/>
							</Grid>
							<Grid
								item
								xs={12}
								className="mt-1">
								<Chip
									variant="outlined"
									className="w-100"
									color={owings && (owings["ke"] <= 0 ? "success" : "error")}
									label={owings ? `Koen & Eline  ${adorned["ke"]}` : ""}
								/>
							</Grid>
							<Grid
								item
								xs={12}
								className="mt-1">
								<Chip
									variant="outlined"
									className="w-100"
									color={owings && (owings["ps"] <= 0 ? "success" : "error")}
									label={owings ? `Pascal & Steve  ${adorned["ps"]}` : ""}
								/>
							</Grid>{" "}
						</Grid>
					</Paper>
					<Paper style={{ marginTop: "1em", marginBottom: "2em", padding: "1em" }}>
						<Grid container>
							<Grid
								className="mt-2"
								item
								xs={12}>
								<Typography
									variant="h3"
									className="clickableObject"
									onClick={() => {
										setBalanceHelpOpen(true);
									}}>
									Logbook <InfoOutlinedIcon />
								</Typography>
								<Typography color="gray">See what's been paid for</Typography>
							</Grid>
							<Grid
								className="mt-2"
								item
								xs={12}>
								<Button
									color="secondary"
									fullWidth
									onClick={() => {
										navigate("/addpayment");
									}}
									variant="contained">
									<AddOutlinedIcon />
									Add
								</Button>
							</Grid>
							<Grid
								style={{ marginTop: "1em" }}
								item
								xs={12}>
								{priceList && (
									<>
										<Table size="small">
											<TableHead>
												<TableRow>
													<TableCell>
														<Typography variant="h5">Cost:</Typography>
													</TableCell>
													<TableCell>
														<Typography variant="h5">Activity:</Typography>
													</TableCell>
													<TableCell>
														<Typography variant="h5">By:</Typography>
													</TableCell>
													{isPortrait ? (
														""
													) : (
														<TableCell>
															<Typography variant="h5">Participants:</Typography>
														</TableCell>
													)}
												</TableRow>
											</TableHead>
											<TableBody>
												{priceList.slice(0, 3).map((element) => {
													return (
														<TableRow
															key={element._id}
															onClick={() => {
																navigate(`/editpayment/${element._id}`);
															}}
															className="clickableObject">
															<TableCell>{PrintMoney(-element.PaymentAmount)}</TableCell>
															<TableCell>{element.PaymentDescription}</TableCell>

															<TableCell>
																{isPortrait ? (
																	<NameBlock
																		nameInput={NameDict[element.PaymentSource]}
																	/>
																) : (
																	NameDict[element.PaymentSource]
																)}
															</TableCell>
															{isPortrait ? (
																""
															) : (
																<TableCell>
																	{element.PaymentGoal.map((element) => {
																		return NameDict[element];
																	}).join(", ")}
																</TableCell>
															)}
														</TableRow>
													);
												})}
											</TableBody>
										</Table>
										{priceList.length > 3 && (
											<div className="text-center">
												<Button
													sx={{ color: "secondary.dark" }}
													href="/balance">
													Show more...
												</Button>
											</div>
										)}
									</>
								)}
							</Grid>
						</Grid>
					</Paper>
				</Container>
			) : (
				""
			)}
		</>
	);
}
