export function PrintMoney(numberInput) {
	const storageDisplay = localStorage.getItem("currencyDisplay") || "euro";
	const isDollar = storageDisplay === "dollar";
	let intNumber;
	if (isDollar) {
		intNumber = numberInput.toFixed(2);
	} else {
		intNumber = (numberInput * 0.92).toFixed(2);
	}

	if (intNumber > 0) {
		return `${isDollar ? "$ " : "€ "} -${Math.abs(intNumber)}`;
	} else {
		return `${" "} ${isDollar ? "$ " : "€ "}${Math.abs(intNumber)}`;
	}
}
